<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20" style="margin-top: 0px">
        <el-col :span="3">
          <el-input v-model="search.name" placeholder="客户姓名" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.weChat" placeholder="微信" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.phone" placeholder="手机号" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.appointCity" placeholder="预约城市" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.type" size="medium" placeholder="预约类型" clearable style="width: 100%">
            <el-option
                v-for="item in typeArray"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              style="width: 100%"
              v-model="createDateArray"
              type="daterange"
              size="medium"
              range-separator="至"
              start-placeholder="预约日期"
              value-format="yyyy-MM-dd"
              end-placeholder="预约日期">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              style="width: 100%"
              v-model="appointDateArray"
              type="daterange"
              range-separator="至"
              size="medium"
              start-placeholder="到店日期"
              value-format="yyyy-MM-dd"
              end-placeholder="到店日期">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:15px">
        <el-col :span="3">
          <el-cascader
              :props="{ checkStrictly: true,
                        expandTrigger: 'hover',
                        multiple: true}"
              multiple="false"
              size="medium"
              :show-all-levels="false"
              style="width: 100%;"
              :options="sourceArray"
              v-model="source"
              placeholder="客资来源"
              ref="source"
              collapse-tags
              @change="cascaderClsoe(source)"
              clearable></el-cascader>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.appointName" size="medium" placeholder="预约项目" clearable style="width: 100%" @change="appointNameChange(search.appointName)">
            <el-option
                v-for="item in appointNameArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.state" placeholder="预约状态" size="medium" clearable @change="stateChange(search.state)">
            <el-option
                v-for="item in stateArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.appointShop" size="medium" placeholder="店铺" clearable style="width: 100%" @change="shopChange(search.appointShop)">
            <el-option
                v-for="item in appointShopArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.service" placeholder="客服" size="medium" clearable @change="serviceChange(search.service)">
            <el-option
                v-for="item in serviceArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="search.appointDress" size="medium" placeholder="礼服师" clearable style="width: 100%" @change="dressChange(search.appointDress)">
            <el-option
                v-for="item in appointDressArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="search.inviter" size="medium" placeholder="预约人" clearable style="width: 100%" @change="inviterChange(search.inviter)">
            <el-option
                v-for="item in inviterArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="23" style="margin-top:15px">
        <el-dropdown placement="bottom-start" style="width: 60%">
          <el-col :span="15">
            <el-input readonly="readonly" class="el-dropdown-link" placeholder="常用条件">
              <el-tag v-if='recordObject && recordObject.sourceName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.sourceName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.projectsName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.projectsName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.stateName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.stateName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.shopName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.shopName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.serviceName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.serviceName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.dressName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.dressName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.inviterName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.inviterName }}</el-tag>
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-button el-dropdown-link type="success" icon="el-icon-star-off" size="medium " @click="collectionRecords()" circle></el-button>
            <el-button type="primary" @click="searchButton" size="medium">搜索</el-button>
            <el-button type="warning" @click="reset" size="medium">重置</el-button>
          </el-col>
          <el-dropdown-menu  slot="dropdown" style="width: 35%;opacity: 0.95">
            <el-dropdown-item
                v-for="item in recordArray"
                :key="item.value"
                :label="item.showString"
                :value="item"
            >{{ item.preferenceSerialNumber }} : <el-tag type="warning" style="width: 90%" @click="useRecord(item)">{{item.showString}}</el-tag>
              <el-button type="danger" icon="el-icon-delete" size="medium" @click="deleteRecords(item)" circle></el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :data="appArray"
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="635"
          style="width: 99%">
        <el-table-column prop="name" label="客户名" align="center" width="120" fixed show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" width="126"></el-table-column>
        <el-table-column prop="weChat" label="微信号" align="center" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="appointDate" label="到店日期" sortable align="center" width="120"></el-table-column>
        <el-table-column prop="appointTime" label="到店时间" align="center" width="120"></el-table-column>
        <el-table-column prop="inviter" label="预约人" align="center" width="120"></el-table-column>
        <el-table-column prop="sourceName" label="来源渠道" align="center" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="appointName" label="预约项目" align="center" width="120"></el-table-column>
        <el-table-column prop="appStateName" label="预约状态" align="center" width="120"></el-table-column>
        <el-table-column prop="appointShop" label="预约店铺" align="center" width="120"></el-table-column>
        <el-table-column prop="service" label="客服" align="center" width="120"></el-table-column>
        <el-table-column label="电话确认" align="center" width="120">
          <template slot-scope="scope">
            {{scope.row.affirm === "1" ? "已确认" : "未确认"}}
          </template>
        </el-table-column>
        <el-table-column prop="isValid" label="到店" align="center" width="120">
          <template slot-scope="scope">
            <span :style="{color: scope.row.isValid === '1' ? 'yellowgreen' :scope.row.isValid === '2' ? '#a5673f' : 'red'}">
              {{scope.row.isValid === "1" ? "未到店" :scope.row.isValid === "2" ? "已到店" : "取消预约"}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="appointDress" label="礼服师" align="center" width="120"></el-table-column>
        <el-table-column prop="appointCosmetics" label="化妆师" align="center" width="120"></el-table-column>
        <el-table-column prop="room" label="房间" align="center" width="120"></el-table-column>
        <el-table-column prop="remark" label="预约备注" align="center" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column width="360" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <per-button type="primary" size="mini" @click="orderAddCloseOpen(scope.row)" per="appoint:order_add">订单</per-button>
              <per-button type="success" size="mini" @click="appEditStateOpen(scope.row)" per="appoint:edit">编辑</per-button>
              <per-button type="warning" size="mini" @click="appArrivalOpen(scope.row)" per="appoint:arrival">到店</per-button>
              <per-button type="primary" size="mini" @click="addAppointYarnOpen(scope.row)" per="appoint:appointYarn">添加预约试礼服</per-button>
              <per-button type="danger" size="mini" @click="cancel(scope.row)" per="appoint:cancel">取消</per-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <appArrival :app="app" :appArrivalState="appArrivalState" v-if="appArrivalState" @close="appArrivalClose"></appArrival>
    <appClothesYarn :app="app" :appointYarnState="appointYarnState" v-if="appointYarnState" @close="appointYarnClose"></appClothesYarn>
    <appEdit :app="app" :appEditState="appEditState" v-if="appEditState" @close="appEditStateClose"></appEdit>
    <orderAdd :app="app" :orderAddState="orderAddState" v-if="orderAddState" @close="orderAddClose"></orderAdd>
    <recordSelect v-if="recordSelect" :recordObject="recordObject" :recordArray="recordArray" :recordState="recordSelect" @close="recordSelectAddClose"/>
  </div>
</template>

<script>
import appArrival from "@/pages/appoint/list/app-arrival"
import appClothesYarn from "@/pages/appoint/list/app-clothesYarn"
import appEdit from "@/pages/appoint/list/app-edit"
import orderAdd from "@/pages/order/order-add"
import recordSelect from "@/components/record/record-select"

export default {
  name: "app-list",
  created() {
    this.pageInit()
  },
  components: {
    appArrival,
    appEdit,
    orderAdd,
    recordSelect,
    appClothesYarn
  },
  data() {
    return {
      // 搜索框
      search:{
        name: "",
        weChat: "",
        phone: "",
        type: "",
        createDate: "",
        appointDate: "",
        inviter: "",
        service: "",
        appointCity: "",
        appointShop: "",
        appointDress: "",
        appointName: "",
        state: "",
      },
      source: "",
      testMessage: "",
      loading: false,
      sourceResult: "",
      createDateArray: [],
      appointDateArray: [],
      appointNameArray: [],
      inviterArray: [],
      appointShopArray: [],
      appointDressArray: [],
      appArray: [],
      stateArray: [],
      sourceArray: [],
      serviceArray: [],
      recordArray: [],
      typeArray: [{value: "售前预约"}, {value: "售后预约"}],
      tenantCrop: localStorage.getItem("tenantCrop"),
      total: 0,
      page: 1,
      limit: 100,
      appArrivalState: false,
      appointYarnState: false,
      appEditState: false,
      orderAddState: false,
      recordSelect: false,
      app: {},
      appointYarn: {},
      recordObject:{
        projectsName:"",
        projectsId: "",
        gradeName: "",
        gradeId: "",
        sourceName: "",
        sourceId: "",
        stateName: "",
        stateId: "",
        shopName: "",
        shopId: "",
        dressName: "",
        dressId: "",
        inviterName: "",
        inviterId: "",
        serviceName: "",
        serviceId: "",
        showString: "",
        empId: JSON.parse(localStorage.getItem("emp")).id,
        empName: JSON.parse(localStorage.getItem("emp")).empName,
        tenantCrop: localStorage.getItem("tenantCrop"),
        recordType: "预约列表",
        preferenceSerialNumber: "",
      }
    }
  },
  methods: {
    pageInit() {
      this.queryAppList()
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryProjectsIds(this.$projectsType.appoint).then(response => {
        this.appointNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryEmpIds().then(response => {
        this.inviterArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        let shops = localStorage.getItem("shops").split(",").map(Number)
        this.appointShopArray = JSON.parse(response.data.data).filter(s => shops.includes(s.value));
      })
      this.$selectUtils.queryDressIds().then(response => {
        this.appointDressArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryStateIds().then(response => {
        this.stateArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryRecord("预约列表").then(response => {
        this.recordArray = response.data.data
        this.recordObject.preferenceSerialNumber = this.recordArray.length + 1
      })
      this.loading = true
    },
    searchButton(){
      this.page=1;
      this.limit=100;
      this.queryAppList();
    },
    // 查询预约列表
    queryAppList() {
      this.search.appointDate = ""
      this.search.createDate = ""
      if (this.appointDateArray !== null && this.appointDateArray.length > 0) {
        this.search.appointDate = this.appointDateArray[0] +" - "+this.appointDateArray[1]
      }
      if (this.createDateArray !== null && this.createDateArray.length > 0) {
        this.search.createDate = this.createDateArray[0] +" - "+this.createDateArray[1]
      }
      this.loading = true
      this.$axios({
        method: "GET",
        url: "/appoint/appointList",
        params: {
          name: this.search.name,
          weChat: this.search.weChat,
          phone: this.search.phone,
          type: this.search.type,
          source: this.sourceResult,
          createDate: this.search.createDate,
          appointDate: this.search.appointDate,
          inviter: this.search.inviter.value,
          service: this.search.service.value,
          appointCity: this.search.appointCity,
          appointShop: this.search.appointShop.value,
          appointDress: this.search.appointDress.value,
          appointName: this.search.appointName.value,
          state: this.search.state.value,
          tenantCrop: this.tenantCrop,
          limit: this.limit,
          page: this.page,
          shopIds: localStorage.getItem("shops"),
        }
      }).then(response => {
        this.appArray = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false
      })
    },
    // 查询预约列表 通过收藏记录查询
    useRecord(val) {
      this.$axios({
        method: "get",
        url: "/appoint/appointList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
          source: val.sourceId,
          inviter: val.inviterId,
          service: val.serviceId,
          appointShop: val.shopId,
          appointDress: val.dressId,
          appointName: val.projectsId,
          state: val.stateId,
          shopIds: localStorage.getItem("shops"),
        }
      }).then(response => {
        console.log(response)
        this.loading = false
        this.appArray = response.data.data.list;
        this.total = response.data.data.total;
      })
    },
    //将记录搜藏存储
    collectionRecords() {
      if (this.recordObject.preferenceSerialNumber > 5) {
        this.recordSelect = true
      } else {
        this.$axios({
          method: "post",
          url: "/record/addRecordOfCustomer",
          params: this.recordObject
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '收藏成功' : response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.reset()
          this.pageInit()
          this.close()
        })
      }
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryAppList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryAppList()
    },
    reset() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
      this.appointDateArray = []
      this.createDateArray = []
      this.source = ""
      this.sourceResult = ""
      this.recordObject.sourceName = ""
      this.recordObject.projectsName = ""
      this.recordObject.stateName = ""
      this.recordObject.shopName = ""
      this.recordObject.serviceName = ""
      this.recordObject.dressName = ""
      this.recordObject.inviterName = ""
    },
    // 预约取消
    cancel(data) {
      if (data.isValid === "2") {
        this.$message({
          message: '当前预约已到店，不可进行取消',
          type: 'warning',
          duration: 1000,
          showClose: true,
        });
      } else if (data.isValid === "3") {
        this.$message({
          message: '当前预约已取消，请勿重复操作',
          type: 'warning',
          duration: 1000,
          showClose: true,
        });
      } else {
        this.$alert("确认要取消 <b>"+ data.name +"</b> 的这条预约吗？"
            ,'取消预约',
            {
              dangerouslyUseHTMLString: true,
              type: "warning",
              showCancelButton: true
        }).then(() => {
          this.$axios({
            method: "POST",
            url: "/appoint/cancelAppointById",
            params: {
              id: data.id,
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '取消预约成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            this.queryAppList()
          })
        }).catch(()=>{})
      }
    },
    appArrivalOpen(data) {
      if (data.isValid === "3") {
        this.$message({
          message: '当前预约已取消，不可进行改操作',
          type: 'warning',
          duration: 1500,
          showClose: true,
        });
        return
      }
      this.app = data
      this.appArrivalState = true
    },
    addAppointYarnOpen(data) {
      console.log(data)
      if (data.isValid === "3") {
        this.$message({
          message: '当前预约已经取消，不可进行操作',
          type: 'warning',
          duration: 1500,
          showClose: true,
        });
        return
      }
      this.app = data
      this.appointYarnState = true
    },
    appArrivalClose() {
      this.appArrivalState = false
      this.queryAppList()
    },
    appointYarnClose() {
      this.appointYarnState = false
      this.queryAppList()
    },
    appEditStateOpen(data) {
      this.app = data
      this.appEditState = true
    },
    appEditStateClose() {
      this.appEditState = false
      this.queryAppList()
    },
    orderAddCloseOpen(data) {
      if (data.isValid === "1" || data.isValid === "3") {
        this.$message({
          message: '当前预约未到店或已取消，不可进行改操作',
          type: 'warning',
          duration: 1500,
          showClose: true,
        });
        return
      }
      this.app = data
      this.orderAddState = true
    },
    orderAddClose() {
      this.orderAddState = false
      this.queryAppList()
    },
    //级联选择关闭
    cascaderClsoe(val) {
      if (""!=val && null!=val) {
        if (""!=this.source) {
          let test = this.source[0]
          Array.from(test)
          if (test.length > 1) {
            this.sourceResult = test[1]
          } else {
            this.sourceResult = test[0]
          }
        }
        this.recordObject.sourceName = this.$refs["source"].getCheckedNodes()[0].label
        this.recordObject.sourceId = this.sourceResult
      } else {
        this.recordObject.sourceName = ""
        this.recordObject.sourceId = ""
        this.sourceResult = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
      console.log(val)
    },
    appointNameChange(val) {
      if (""!=val && null!=val) {
        this.recordObject.projectsName = val.name
        this.recordObject.projectsId = val.value
      } else {
        this.recordObject.projectsName = ""
        this.recordObject.projectsId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    stateChange(val) {
      if (""!=val && null!=val) {
        this.recordObject.stateName = val.name
        this.recordObject.stateId = val.value
      } else {
        this.recordObject.stateName = ""
        this.recordObject.stateId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
      console.log(val)
    },
    shopChange(val) {
      console.log(val)
      if (""!=val && null!=val) {
        this.recordObject.shopName = val.name
        this.recordObject.shopId = val.value
      } else {
        this.recordObject.shopName = ""
        this.recordObject.shopId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    serviceChange(val) {
      if (""!=val && null!=val) {
        this.recordObject.serviceName = val.name
        this.recordObject.serviceId = val.value
      } else {
        this.recordObject.serviceName = ""
        this.recordObject.serviceId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    dressChange(val) {
      if (""!=val && null!=val) {
        this.recordObject.dressName = val.name
        this.recordObject.dressId = val.value
      } else {
        this.recordObject.dressName = ""
        this.recordObject.dressId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    inviterChange(val) {
      if (""!=val && null!=val) {
        this.recordObject.inviterName = val.name
        this.recordObject.inviterId = val.value
      } else {
        this.recordObject.inviterName = ""
        this.recordObject.inviterId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    showString() {
      return this.recordObject.sourceName + ";" + this.recordObject.projectsName + ";"
          + this.recordObject.stateName + ";" + this.recordObject.shopName + ";"
          + this.recordObject.serviceName + ";" + this.recordObject.dressName + ";"
          + this.recordObject.inviterName
    },
    recordSelectAddClose() {
      this.pageInit()
      this.recordSelect = false
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>