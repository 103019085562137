<template>
  <div>
    <el-dialog
        title="预约编辑"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="客户名">
              <el-input readonly v-model="app.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="手机号">
              <el-input readonly v-model="app.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="微信号">
              <el-input readonly v-model="app.weChat"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="到店日期" prop="appointDate">
              <el-date-picker type="date" placeholder="到店日期"
                              v-model="form.appointDate"
                              value-format="yyyy-MM-dd"
                              style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="到店时间" prop="appointTime">
              <el-time-select
                  v-model="form.appointTime"
                  style="width: 100%;"
                  :picker-options="{
                    start: '06:00',
                    step: '00:30',
                    end: '21:00'
                  }"
                  placeholder="选择时间">
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="预约人" prop="inviter">
              <el-select v-model="form.inviter" placeholder="预约人">
                <el-option
                    v-for="item in inviterArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="预约项目" prop="appointName">
              <el-select v-model="form.appointName" placeholder="预约项目">
                <el-option
                    v-for="item in appointNameArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="预约店铺" prop="appointShop">
              <el-select v-model="form.appointShop" placeholder="预约店铺" ref="shop">
                <el-option
                    v-for="item in appointShopArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="7">
            <el-form-item label="预设礼服师">
              <el-select v-model="form.appointDress" placeholder="礼服师">
                <el-option
                    v-for="item in appointDressArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="预设化妆师">
              <el-select v-model="form.appointCosmetics" placeholder="化妆师">
                <el-option
                    v-for="item in appointCosmeticsArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="备注">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="form.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" @click="appAdd()" size="medium">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "app-edit",
  created() {
    this.pageInit()
    console.log(this.form)
  },
  props: {
    appEditState: {
      type: Boolean,
      default: false,
      required: true,
    },
    app: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      state: this.appEditState,
      form: {
        ...this.app
      },
      rules: {
        appointDate: [{required: true, message: "请选择到店日期", trigger: "change"}],
        appointTime: [{required: true, message: "请选择到店时间", trigger: "change"}],
        inviter: [{required: true, message: "请选择预约人", trigger: "change"}],
        appointName: [{required: true, message: "请选择预约项目", trigger: "change"}],
        appointShop: [{required: true, message: "请选择预约店铺", trigger: "change"}],
      },
      inviterArray: [],
      appointNameArray: [],
      appointShopArray: [],
      appointDressArray: [],
      appointCosmeticsArray: [],
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryEmpIds().then(response => {
        this.inviterArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.appointShopArray = JSON.parse(response.data.data);
      })
      let stateFlag = this.form.type === "售前预约" ? 1 : 2;
      this.$selectUtils.queryAppProjectsIds(this.$projectsType.appoint
          ,stateFlag).then(response => {
        this.appointNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryDressIds().then(response => {
        this.appointDressArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryCosmeticsIds().then(response => {
        this.appointCosmeticsArray = JSON.parse(response.data.data);
      })
      this.$axios({
        method: "get",
        url: "/appoint/queryAppointById",
        params:{
          id: this.app.id
        }
      }).then(response => {
        this.form = response.data.data
      })
    },
    close() {
      this.$emit("close");
    },
    appAdd() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert("确认要修改<b>"+ this.app.name +"</b>的这条预约吗？"
            ,'添加预约',
            {
              dangerouslyUseHTMLString: true,
              type: "success",
              showCancelButton: true
          }).then(()=>{
            this.$axios({
              method: "POST",
              url: "/appoint/updateAppoint",
              params: this.form
            }).then(response=>{
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '预约添加成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              this.close();
            })
          }).catch(()=>{});
        }
      })
    },
  },
}
</script>

<style scoped>

</style>