<template>
  <div>
    <el-dialog
        title="添加预约试礼服"
        width="80%"
        top="5vh"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <fieldset style="width:97%">
        <legend>搜索信息</legend>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select v-model="search.styleType" placeholder="款式类型" clearable>
              <el-option
                  v-for="item in styleTypeArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input v-model="search.styleName" placeholder="款式编号 如: 0001" clearable></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="search.clothesShop" placeholder="店铺" clearable @change="queryClothesPosition">
              <el-option
                  v-for="item in clothesShopArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="search.clothesPosition" placeholder="婚纱位置" clearable>
              <el-option
                  v-for="item in clothesPositionArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="queryClothes(1)" size="medium">搜索</el-button>
            <el-button type="warning" @click="reset" size="medium">重置</el-button>
          </el-col>
        </el-row>
      </fieldset>
      <el-row style="margin-left: 15px;margin-top: 15px">
        <el-col :span="12">
          <div style="height: 65vh;overflow: auto;" class="clothes">
            <el-row :gutter="20" style="width: 99%">
              <el-col :span="8" v-for="item in clothesArray" style="margin-bottom: 25px">
                <el-row>
                  <el-image :src="baseUrl+item.styleImage" style="width: 90%; height: 260px"
                            :preview-src-list="[baseUrl+item.styleImage]" lazy>
                  </el-image>
                </el-row>
                <el-row style="width: 90%">
                  <span style='float: left'>{{item.shopName}}</span>
                  <span style='float: right'>{{item.positionName}}</span>
                </el-row>
                <div style="text-align: center;margin-top: 5px;width: 90%">
                  <a href="#" style="color:#409eff" @click="chooseClothes(item)">
                    {{item.styleType}}-{{item.styleName}}-{{item.clothesSize}}-{{item.clothesNo}}选择此件
                  </a>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="height: 65vh;overflow: auto;margin-top:1%" class="clothes">
            <el-row style="font-size: 16px">
              <el-col :span="6">
                客户名：{{this.app.name}}
              </el-col>
              <el-col :span="10">
                联系方式：{{this.app.phone}}
              </el-col>
            </el-row>
            <el-row style="font-size: 16px;margin-top: 10px">
              <el-col :span="10">
                预约店铺：{{this.app.appointShop}}
              </el-col>
            </el-row>
            <el-row style="font-size: 16px;margin-top: 10px">
              <el-col :span="10">
                到店日期：{{this.app.appointDate}}
              </el-col>
            </el-row>
            <el-divider content-position="left">测试选中礼服</el-divider>
            <el-form label-width="80px" style="width: 97%" :model="form" ref="form">
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-form-item label="礼服编号" prop="clothes">
                    <el-input v-model="clothesName" placeholder="礼服编号" readonly></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-button type="primary" @click="addCheckAppointSchedule">检查</el-button>
                </el-col>
              </el-row>
            </el-form>
            <el-divider content-position="left">检查结果如下</el-divider>
            <el-table
                :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
                :data="checkAppointScheduleList"
                border>
              <el-table-column prop="orderNo" label="礼服名" align="center" :show-overflow-tooltip="true" width="120px">
                <template slot-scope="scope">
                  {{scope.row.styleType}}-{{scope.row.styleName}}-{{scope.row.clothesSize}}-{{scope.row.clothesNo}}
                </template>
              </el-table-column>
              <el-table-column prop="arriveDate" label="到店日期" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="scheduleState" label="是否撞挡" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span :style="{color: scope.row.scheduleState === 1 ? '#409eff' : '#f56c6c'}">
                      {{scope.row.scheduleState === 1 ? "可用" : "撞挡"}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="isLocal" label="是否在本地" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span :style="{color: scope.row.isLocal === 1 ? '#409eff' : '#f56c6c'}">
                      {{scope.row.isLocal === 1 ? "在本地" : "不在本地"}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="220px">
                <template slot-scope="scope">
                  <el-button-group>
                    <el-button type="success" size="mini" @click="addAppointYarn(scope.row)">添加预约试礼服</el-button>
                    <el-button type="danger" size="mini" @click="removeCheckAppointSchedule(scope.row)">移除</el-button>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
            <el-divider content-position="left">{{this.app.name}}  的预约试礼服记录</el-divider>
            <el-table
                :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
                :data="appointYarnArray"
                border>
              <el-table-column prop="orderNo" label="礼服名" align="center" :show-overflow-tooltip="true" width="120px">
                <template slot-scope="scope">
                  {{scope.row.styleType}}-{{scope.row.styleName}}-{{scope.row.clothesSize}}-{{scope.row.clothesNo}}
                </template>
              </el-table-column>
              <el-table-column prop="positionName" label="所属位置" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="arriveDate" label="到店日期" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column label="是否撞挡" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span :style="{color: scope.row.scheduleState === 1 ? '#409eff' : '#f56c6c'}">
                    {{ scope.row.scheduleState === 1 ? "可用" : "撞挡" }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="220px">
                <template slot-scope="scope">
                  <el-button-group>
                    <el-button type="warning" size="mini" @click="showStyleImage(scope.row)">图片</el-button>
                    <el-button type="danger" size="mini" @click="removeAppointYarn(scope.row)">移除</el-button>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "app-clothesYarn",
  created() {
    this.pageInit()
    this.$nextTick(() => {
      const el = document.querySelector('.clothes');
      const offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        const scrollTop = el.scrollTop;
        const scrollHeight = el.scrollHeight;
        if ((offsetHeight + scrollTop) - scrollHeight >= -1) {
          this.page++
          this.queryClothes(2)
        }
      };
    });
  },
  props: {
    appointYarnState: {
      type: Boolean,
      default: false,
      required: true,
    },
    app: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      state: this.appointYarnState,
      tenantCrop: localStorage.getItem("tenantCrop"),
      scheduleRule: "",
      search: {
        styleType: "",
        styleName: "",
        clothesShop: "",
        clothesPosition: "",
      },
      clothesName: "",
      form: {
        clothes: "",
      },
      appointYarnArray: [],
      checkAppointScheduleList: [],
      styleImageList: [],
      styleTypeArray: [],
      clothesShopArray: [],
      clothesPositionArray: [],
      clothesArray: [],
      page: 1,
      limit: 6,
      baseUrl: "https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/",

    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    pageInit() {
      this.$selectUtils.queryStyleTypeIds().then(response => {
        this.styleTypeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.clothesShopArray = JSON.parse(response.data.data);
      })
      this.queryAppointYarnList()
      this.queryCheckAppointSchedule()
    },
    queryClothesPosition(shopId) {
      this.$selectUtils.queryPositionIdsByShop(shopId).then(response => {
        this.clothesPositionArray = JSON.parse(response.data.data)
      })
    },
    queryClothes(type) {
      if (type === 1) {
        this.clothesArray = []
        this.page = 1
      }
      this.$axios({
        method: "GET",
        url: "/clothes/clothesList",
        params: {
          ...this.search,
          tenantCrop: this.tenantCrop,
          page: this.page,
          limit: this.limit,
        }
      }).then(response => {
        this.clothesArray.push(...response.data.data.list)
      })
    },
    chooseClothes(data) {
      this.clothesName = data.styleType+"-"+data.styleName+"-"+data.clothesSize+"-"+data.clothesNo
      this.form.clothes = data.clothesId
    },
    addAppointYarn(val) {
      if (val.isLocal == 0 || val.scheduleState == 0) {
        this.$message({
          showClose: true,
          message: "婚纱撞挡或不在本地，无法添加预约试礼服",
          type: 'error',
          duration: 1500,
        });
        return;
      }
      this.$axios({
        method: 'post',
        url: '/appointYarn/saveAppointYarn',
        params: {
          appId: val.appId,
          arriveDate: val.arriveDate,
          tenantCrop: val.tenantCrop,
          clothesId: val.clothesId,
          dress: val.dress,
          checkAppointId: val.id
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '添加预约试礼服成功': response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        this.queryAppointYarnList()
        this.queryCheckAppointSchedule()
      })
    },
    addCheckAppointSchedule() {
      this.$axios({
        method: 'post',
        url: '/CheckAppointSchedule/saveCheckAppointSchedule',
        params: {
          appId: this.app.id,
          arriveDate: this.app.appointDate,
          tenantCrop: this.tenantCrop,
          clothesId: this.form.clothes,
          dress: JSON.parse(localStorage.getItem("emp")).id
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '礼服档期和位置查询成功，详情如表格所示': response.data.msg,
          type: flag ? 'success':'error',
          duration: 2000,
        });
        this.queryAppointYarnList()
        this.queryCheckAppointSchedule()
      })
    },
    queryAppointYarnList() {
      this.$axios({
        method: "GET",
        url: "/appointYarn/queryAppointYarnList",
        params: {
          appId: this.app.id,
        },
      }).then(response => {
        this.appointYarnArray = response.data.data
      })
    },
    queryCheckAppointSchedule() {
      this.$axios({
        method: "GET",
        url: "/CheckAppointSchedule/queryCheckAppointSchedule",
        params: {
          appId: this.app.id,
        },
      }).then(response => {
        this.checkAppointScheduleList = response.data.data
      })
    },
    //查看预约试礼服图片
    async showStyleImage(data) {
      const response = await this.$axios({
        method:"GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: data.styleId,
        }
      });
      this.styleImageList = response.data.data
      this.$viewerApi({
        images: this.styleImageList,
      })
    },
    //移除预约试礼服
    removeAppointYarn(data) {
      this.$alert("确认要移除改预约试礼服？",
          "预约试礼服移除", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(()=>{
        this.$axios({
          method: "delete",
          url: "/appointYarn/deleteAppointYarnById",
          params:{
            yarnId:data.yarnId
          }
        }).then(response=> {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '预约试礼服移除成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.queryAppointYarnList()
        })
      }).catch(() => {})
    },
    removeCheckAppointSchedule(data) {
      this.$alert("确认要移除改预约试礼服请求吗？",
          "预约试礼服请求移除", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(()=>{
        this.$axios({
          method: "delete",
          url: "/CheckAppointSchedule/removeCheckAppointSchedule",
          params:{
            id:data.id
          }
        }).then(response=> {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '预约试礼服请求移除成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.queryCheckAppointSchedule()
        })
      }).catch(() => {})
    },
    reset() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
    },
  },
}
</script>

<style scoped>
.el-card__body {
  padding: 0px;
}
a {text-decoration: none}
.clothes::-webkit-scrollbar {
  display: none;
}
</style>